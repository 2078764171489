// PLEASE DO NOT FORMAT THIS FILE
// PLEASE DO NOT FORMAT THIS FILE
import React from 'react'
import ProductDetailsNew from './components/products/ProductDetailsNew'
const PortalProductGrid = React.lazy(() => import('../src/components/portals/PortalProductGrid'))
const PurchaseOrderItemsGrid = React.lazy(() => import('../src/components/purchase_order/PurchaseOrderItemsGrid'))
const PortalsGrid = React.lazy(() => import("../src/components/portals/PortalsGrid"))
const ProductShowPOC = React.lazy(() => import('./components/products/ProductShowPOC.js'))
const ClientsGrid = React.lazy(() => import('../src/components/clients/ClientsGrid'))
const CustomersGrid = React.lazy(() => import('../src/components/users/CustomersGrid'))
const GoodReceiptItemList = React.lazy(() => import('../src/components/purchase_order/GoodReceiptItemList'))
const ImportCustomersGrid = React.lazy(() => import('../src/components/portals/ImportCustomersGrid'))
const LocationGroupsList = React.lazy(() => import('../src/components/portals/LocationGroupsList'))
const OrdersGrid = React.lazy(() => import('../src/components/orders/OrdersGrid'))
const OrdersReviewGrid = React.lazy(() => import('../src/components/orders/review/OrdersReviewGrid'))
const PageList = React.lazy(() => import('../src/components/portals/PageList'))
const PortalShow = React.lazy(() => import('./components/portals/PortalShow.js'))
const PortalShowEditPOC = React.lazy(() => import('../src/components/portals/PortalShowEditPOC'))
const ProductsGrid = React.lazy(() => import('../src/components/products/ProductsGrid'))
const ShipperHqBoxesGrid = React.lazy(() => import('../src/components/products/ShipperHqBoxesGrid'))
const ShipperHqGroupsGrid = React.lazy(() => import('../src/components/products/ShipperHqGroupsGrid'))
const ShipperHqOriginsGrid = React.lazy(() => import('../src/components/products/ShipperHqOriginsGrid'))
const ShipperHqPackingRulesGrid = React.lazy(() => import('../src/components/products/ShipperHqPackingRulesGrid'))
const ClientCreate = React.lazy(() => import('../src/components/clients/ClientCreate'))
const ClientEdit = React.lazy(() => import('../src/components/clients/ClientEdit'))
const ClientShow = React.lazy(() => import('../src/components/clients/ClientShow'))
const ClientDelete = React.lazy(() => import('../src/components/clients/ClientDelete'))
const ClientImage = React.lazy(() => import('../src/components/clients/ClientImage'))
const PortalList = React.lazy(() => import('../src/components/portals/PortalList'))
const PortalNew = React.lazy(() => import('../src/components/portals/PortalNew'))
const PortalEdit = React.lazy(() => import('../src/components/portals/PortalEdit'))
const PortalDelete = React.lazy(() => import('../src/components/portals/PortalDelete'))
const PageShow = React.lazy(() => import('../src/components/pages/PageShow'))
const PageNew = React.lazy(() => import('../src/components/pages/PageNew'))
const PageEdit = React.lazy(() => import('../src/components/pages/PageEdit'))
const PageDelete = React.lazy(() => import('../src/components/pages/PageDelete'))
const GenericTemplateEdit = React.lazy(() => import('../src/components/templates/GenericTemplateEdit'))
const AboutTemplateEdit = React.lazy(() => import('../src/components/templates/AboutTemplateEdit'))
const HomeTemplateEdit = React.lazy(() => import('../src/components/templates/HomeTemplateEdit'))
const DomainNew = React.lazy(() => import('../src/components/domains/DomainNew'))
const DomainDelete = React.lazy(() => import('../src/components/domains/DomainDelete'))
const DomainEdit = React.lazy(() => import('../src/components/domains/DomainEdit'))
const ClientImageDelete = React.lazy(() => import('./components/clients/ClientImageDelete'))
const ProductNew = React.lazy(() => import('../src/components/products/ProductNew'))
const ProductShow = React.lazy(() => import('../src/components/products/ProductShow'))
const ProductEdit = React.lazy(() => import('../src/components/products/ProductEdit'))
const ProductList = React.lazy(() => import('../src/components/products/ProductList'))
const ProductWarehouseList = React.lazy(() => import('../src/components/products/ProductWarehouseList'))
const LocationProductRemove = React.lazy(() => import('../src/components/locations/LocationProductRemove'))
const ProductArtwork = React.lazy(() => import('../src/components/products/ProductArtwork'))
const SimpleSkuNewForm = React.lazy(() => import('./components/products/SimpleSkuNew/SimpleSkuNewForm.js'))
import AuthorizedAdminEmailDomainCreate from './components/admins/AuthorizedAdminEmailDomainCreate'
const AuthorizedAdminEmailDomainsShow = React.lazy(() => import('./components/admins/AuthorizedAdminEmailDomainsShow'))
import AuthorizedAdminEmailDomainDelete from './components/admins/AuthorizedAdminEmailDomainDelete'

const IPWhitelistManagement = React.lazy(() => import('./components/admins/IPWhitelistManagement'))
const IPBlacklistManagement = React.lazy(() => import('./components/admins/IPBlacklistManagement'))
const BundleCreate = React.lazy(() => import('./components/bundles/BundleCreate'))
const BundleEdit = React.lazy(() => import('./components/bundles/BundleEdit'))
const UserList = React.lazy(() => import('./components/users/UserList'))
const UserShow = React.lazy(() => import('./components/users/UserShow'))
const UserDelete = React.lazy(() => import('./components/users/UserDelete'))
const UserNew = React.lazy(() => import('./components/users/UserNew'))
const UserEdit = React.lazy(() => import('./components/users/UserEdit'))
const InitiatePasswordResetForAllUsers = React.lazy(() => import('./components/users/InitiatePasswordResetForAllUsers'))
const LocationShow = React.lazy(() => import('../src/components/locations/LocationShow'))
const LocationNew = React.lazy(() => import('../src/components/locations/LocationNew'))
const LocationEdit = React.lazy(() => import('../src/components/locations/LocationEdit'))
const LocationDelete = React.lazy(() => import('../src/components/locations/LocationDelete'))
const LocationCategoryList = React.lazy(() => import('./components/categories/LocationCategoryList'))
const ProductDetailsShow = React.lazy(() => import('../src/components/products/ProductDetailsShow'))
const ProductDetailsEdit = React.lazy(() => import('../src/components/products/ProductDetailsEdit'))
const ProductConfigWizardForm = React.lazy(() => import('./components/products/ConfigWizard/ProductConfigWizardForm'))
const CategoryDelete = React.lazy(() => import('./components/categories/CategoryDelete'))
const ProductDetailsLocationShow = React.lazy(() => import('../src/components/products/ProductDetailsLocationShow'))
const ProductDetailsLocationEdit = React.lazy(() => import('../src/components/products/ProductDetailsLocationEdit'))
const ProductDetailsGenericEdit = React.lazy(() => import('../src/components/products/ProductDetailsGenericEdit'))
const ProductDetailsClientShow = React.lazy(() => import('../src/components/products/ProductDetailsClientShow'))
const ProductDetailsClientEdit = React.lazy(() => import('../src/components/products/ProductDetailsClientEdit'))
const OrderErrorsGrid = React.lazy(() => import("../src/components/orders/OrderErrorsGrid"))


// Configurable Variant
const VariantNew = React.lazy(() => import('./components/product_configuration/VariantNew'))
const VariantShowEdit = React.lazy(() => import('./components/product_configuration/VariantShowEdit'))
const ConfigurableVariantList = React.lazy(() => import('./components/product_configuration/ConfigurableVariantList'))
const VariantDelete = React.lazy(() => import('./components/product_configuration/VariantDelete'))

// Configurable Options
const OptionShowEdit = React.lazy(() => import('./components/configurable_options/OptionShowEdit'))
const OptionNew = React.lazy(() => import('./components/configurable_options/OptionNew'))
const OptionDelete = React.lazy(() => import('./components/configurable_options/OptionDelete'))

const Page404                    = React.lazy(() => import("../src/components/error_pages/Page404"));
const Page500                    = React.lazy(() => import("../src/components/error_pages/Page500"));
const NewCustomerGroup           = React.lazy(() => import("./components/customer_groups/NewCustomerGroup"));
const NewCustomerGroupRule       = React.lazy(() => import("./components/customer_group_rules/NewCustomerGroupRule"));
const CustomerGroupRuleEdit      = React.lazy(() => import("./components/customer_group_rules/CustomerGroupRuleEdit"));
const CustomerGroupShow          = React.lazy(() => import("./components/customer_groups/CustomerGroupShow"));
const CustomerGroupRuleShow      = React.lazy(() => import("./components/customer_group_rules/CustomerGroupRuleShow"));
const RuleHiddenProductRemove    = React.lazy(() => import("./components/customer_group_rules/RuleHiddenProductRemove"));
const RuleHiddenCategoryRemove   = React.lazy(() => import("./components/customer_group_rules/RuleHiddenCategoryRemove"));
const WarehouseList              = React.lazy(() => import("./components/warehouses/WarehouseList"));
const WarehouseNew               = React.lazy(() => import("./components/warehouses/WarehouseNew"));
const WarehouseEdit              = React.lazy(() => import("./components/warehouses/WarehouseEdit"));
const WarehouseShow              = React.lazy(() => import("./components/warehouses/WarehouseShow"));
const WarehouseDelete            = React.lazy(() => import("./components/warehouses/WarehouseDelete"));
const InventoryLog               = React.lazy(() => import("./components/warehouses/InventoryLog"));
const PageContentEdit            = React.lazy(() => import("./components/pages/PageContentEdit"));
const PageContentBlockDelete     = React.lazy(() => import("./components/pages/PageContentBlockDelete"));
const OrderApprovalList          = React.lazy(() => import("./components/orders/order_approval/OrderApprovalList"));
// const OrderList                 = React.lazy(() => import("./components/orders/OrderList"));
const OrderLogsList              = React.lazy(() => import("./components/orders/OrderLogsList"));
const OrderSplitPage             = React.lazy(() => import("./components/orders/OrderSplitPage"));
const CardTransactionsList       = React.lazy(() => import("./components/card_payments/CardTransactionsList"));
const ImageDelete                = React.lazy(() => import("./components/images/ImageDelete"));
const ProductSkuShow             = React.lazy(() => import("./components/product_skus/ProductSkuShow"));
const NewBaseOverride            = React.lazy(() => import("./components/product_skus/NewBaseOverride"));
const EditBaseOverride           = React.lazy(() => import("./components/product_skus/EditBaseOverride"));
const ArtworkCreate              = React.lazy(() => import("./components/artworks/ArtworkCreate"));
const ArtworkList                = React.lazy(() => import("./components/artworks/ArtworkList"));
const ArtworkShow                = React.lazy(() => import("./components/artworks/ArtworkShow"));
const ArtworkDelete              = React.lazy(() => import("./components/artworks/ArtworkDelete"));
const ArtworkPositioning         = React.lazy(() => import("./components/artworks/ArtworkPositioning"));
const FulfilmentView = React.lazy(() => import("./components/orders/FulfillmentView"));
const FulfilmentViewNew = React.lazy(() => import("./components/orders/FulfilmentViewNew"));

// Promotions
// PLEASE DO NOT FORMAT THIS FILE
const PromotionList = React.lazy(() => import('./components/promotions/PromotionList'))
const PromotionNew = React.lazy(() => import('./components/promotions/PromotionNew'))
const PromotionShow = React.lazy(() => import('./components/promotions/PromotionShow'))
const PromotionDelete = React.lazy(() => import('../src/components/promotions/PromotionDelete'))
const PromotionRuleDelete = React.lazy(() => import('../src/components/promotion_rules/PromotionRuleDelete'))
const PromotionRuleNew = React.lazy(() => import('./components/promotion_rules/PromotionRuleNew'))
const PromotionRuleShow = React.lazy(() => import('./components/promotion_rules/PromotionRuleShow'))
import PromotionValidationDelete from '../src/components/promotion_validations/PromotionValidationDelete'
const PromotionValidationNew = React.lazy(() => import('./components/promotion_validations/PromotionValidationNew'))
const PromotionValidationShow = React.lazy(() => import('./components/promotion_validations/PromotionValidationShow'))
const PromotionCodeDelete = React.lazy(() => import('../src/components/promotion_codes/PromotionCodeDelete'))
const PromotionCodeEdit = React.lazy(() => import('../src/components/promotion_codes/PromotionCodeEdit'))
const PromotionCodeNew = React.lazy(() => import('./components/promotion_codes/PromotionCodeNew'))
const SettingList = React.lazy(() => import('./components/settings/SettingList'))
const SettingEdit = React.lazy(() => import('../src/components/settings/SettingEdit'))

// Purchase orders
// PLEASE DO NOT FORMAT THIS FILE
const PurchaseOrderNew = React.lazy(() => import('./components/purchase_order/PurchaseOrderNew'))
const PurchaseOrderEdit = React.lazy(() => import('./components/purchase_order/PurchaseOrderEdit'))
const PurchaseOrderShow = React.lazy(() => import('./components/purchase_order/PurchaseOrderShow'))
const GoodsReceiptNew = React.lazy(() => import('./components/good_receipt/GoodReceiptNew'))
const PurchaseOrderList = React.lazy(() => import('./components/purchase_order/PurchaseOrderList'))
const PurchaseOrderItemsList = React.lazy(() => import('./components/purchase_order/PurchaseOrderItemsList'))
const PurchaseOrderDuplicate = React.lazy(() => import('./components/purchase_order/PurchaseOrderDuplicate'))
const GoodsReceiptLog = React.lazy(() => import('./components/purchase_order/GoodsReceiptLog'))

// Vendors
// PLEASE DO NOT FORMAT THIS FILE
const VendorList = React.lazy(() => import('./components/vendors/VendorList'))
const VendorNew = React.lazy(() => import('./components/vendors/VendorNew'))
const VendorEdit = React.lazy(() => import('./components/vendors/VendorEdit'))
const VendorShow = React.lazy(() => import('./components/vendors/VendorShow'))
const VendorDelete = React.lazy(() => import('./components/vendors/VendorDelete'))

// TemplateForms
// PLEASE DO NOT FORMAT THIS FILE
const TemplateFormNew = React.lazy(() => import('./components/template_form/TemplateFormNew'))
const TemplateEdit = React.lazy(() => import('./components/template_form/TemplateEdit'))
const TemplateFormShow = React.lazy(() => import('./components/template_form/TemplateFormShow'))
const TemplateFormList = React.lazy(() => import('./components/template_form/TemplateFormList'))
const TemplateFormDelete = React.lazy(() => import('./components/template_form/TemplateFormDelete'))

// Roles
// PLEASE DO NOT FORMAT THIS FILE
const RoleList = React.lazy(() => import('./components/roles/RoleList'))
const RoleNew = React.lazy(() => import('./components/roles/RoleNew'))
const RoleShow = React.lazy(() => import('./components/roles/RoleShow'))
const RoleEdit = React.lazy(() => import('./components/roles/RoleEdit'))
const RoleDelete = React.lazy(() => import('./components/roles/RoleDelete'))

// Artwork
// PLEASE DO NOT FORMAT THIS FILE
const ArtworkEdit = React.lazy(() => import('./components/artworks/ArtworkEdit'))
const ArtworkFamilyCreate = React.lazy(() => import('./components/artwork_families/ArtworkFamilyCreate'))

import SessionsLayout from './containers/SessionsLayout'
const ArtworkFieldList = React.lazy(() => import('./components/artworks/ArtworkFieldList'))

// Admins
// PLEASE DO NOT FORMAT THIS FILE
const AdminsGrid = React.lazy(() => import('../src/components/admins/AdminsGrid'))
const AdminList = React.lazy(() => import('./components/admins/AdminList'))
const AdminShow = React.lazy(() => import('./components/admins/AdminShow'))
const AdminDelete = React.lazy(() => import('./components/admins/AdminDelete'))
const AdminNew = React.lazy(() => import('./components/admins/AdminNew'))
const AdminEdit = React.lazy(() => import('./components/admins/AdminEdit'))

// Dropship Batches
// PLEASE DO NOT FORMAT THIS FILE
const DropshipBatchShow = React.lazy(() => import('./components/dropship_batches/DropshipBatchShow'))
const Dashboard = React.lazy(() => import('./views/Dashboard'))

// Passwords
// PLEASE DO NOT FORMAT THIS FILE
const UpdatePasswordForm = React.lazy(() => import('./containers/DefaultLayout/components/navbar/UpdatePasswordForm'))


const SsoFeaturebaseRedirect = React.lazy(() => import('./components/external_sso/SsoFeaturebaseRedirect'))
export const DefaultRoute = '/dashboard'
export const Routes = [
  { path: '/', name: 'Home', component: Dashboard, exact: true },
  {
    layout: 'BlankLayout',
    path: '/login',
    name: 'Sign In',
    component: SessionsLayout,
    exact: true,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    breadcrumb: 'Dashboard',
  },
  {
    path: '/update_password',
    name: 'Update Password Form',
    component: UpdatePasswordForm,
  },
  // External SSO Endpoints
  {
    layout: 'BlankLayout',
    path: '/sso/external/featurebase',
    name: 'Sign In',
    component: SsoFeaturebaseRedirect,
    exact: true,
  },

  /// NEW CLIENT ROUTES
  {
    path: '/clients/create',
    name: 'Create Client',
    granted: 'CLIENT.create',
    component: ClientCreate,
  },
  {
    path: '/clients/:id/edit',
    name: 'Edit Client',
    granted: 'CLIENT.update',
    component: ClientEdit,
  },
  {
    path: '/clients/:id/delete',
    name: 'Delete Client',
    granted: 'CLIENT.delete',
    component: ClientDelete,
  },
  {
    path: '/clients/:id',
    name: 'Client',
    granted: ['CLIENT.read', 'PORTAL.read'],
    component: ClientShow,
  },
  {
    path: '/clients',
    name: 'Clients',
    granted: ['CLIENT.read', 'PORTAL.read'],
    component: ClientsGrid,
  },

  /// OLD CLIENT ROUTES
  // {
  //   path: '/clients_old',
  //   name: 'Clients ',
  //   granted: ['CLIENT.read', 'PORTAL.read'],
  //   component: ClientList,
  // // },
  // {
  //   path: '/new_client/:id',
  //   name: 'Client',
  //   granted: ['CLIENT.read', 'PORTAL.read'],
  //   component: ClientCreate,
  // },
  // {
  //   path: '/edit_client/:id',
  //   name: 'Edit Client',
  //   granted: 'CLIENT.update',
  //   component: ClientEdit,
  // },
  // {
  //   path: '/show_client/:id',
  //   name: 'Client',
  //   granted: ['CLIENT.read', 'PORTAL.read'],
  //   component: ClientShow,
  // },
  // {
  //   path: '/delete_client/:id',
  //   name: 'Delete Client',
  //   granted: 'CLIENT.delete',
  //   component: ClientDelete,
  // },
  {
    path: '/client_image/:id',
    name: 'Client Image',
    granted: 'CLIENT.update',
    component: ClientImage,
  },
  {
    path: '/new_portal/:client_id',
    name: 'New Portal',
    granted: 'PORTAL.create',
    component: PortalNew,
  },
  {
    path: '/portals/:id/edit',
    name: 'Portal Edit',
    component: PortalEdit,
    granted: ['PORTAL.read', 'LOCATION.read', 'CMS.read', 'COOP_FUNDING.read', 'CUST_GROUPS.read', 'COOP_CREDIT.read']
   },

  {
    path: '/portals/:id', name: 'Portal Show',
    component: PortalShow,
    granted: ['PORTAL.read', 'LOCATION.read', 'CMS.read', 'COOP_FUNDING.read', 'CUST_GROUPS.read', 'COOP_CREDIT.read']
  },

  {
    path: '/show_portal/:portalId/new_promotion',
    name: 'Create Promotion',
    component: PromotionNew,
  },
  {
    path: '/show_portal/:portalId/edit_location_groups',
    name: 'LocationGroupsList',
    granted: 'LOCATIONS.create',
    component: LocationGroupsList,
  },


  {
    path: '/show_portal/:id',
    name: 'Portal',
    granted: ['PORTAL.read', 'LOCATION.read', 'CMS.read', 'COOP_FUNDING.read', 'CUST_GROUPS.read', 'COOP_CREDIT.read'],
    component: PortalShow,
  },

  // {
  //   path: '/portals/:id',
  //   name: 'Portal',
  //   granted: ['PORTAL.read', 'LOCATION.read', 'CMS.read', 'COOP_FUNDING.read', 'CUST_GROUPS.read', 'COOP_CREDIT.read'],
  //   component: PortalShow,
  // },
  {
    path: '/delete_portal/:id',
    name: 'Delete Portal',
    granted: 'PORTAL.delete',
    component: PortalDelete,
  },
  {
    path: '/show_page/:id',
    name: 'Page',
    granted: ['PORTAL.read', 'CMS.read'],
    component: PageShow,
  },
  {
    path: '/edit_page/:id',
    name: 'Edit Page',
    granted: ['PORTAL.read', 'CMS.update'],
    component: PageEdit,
  },
  {
    path: '/new_page/:portal_id',
    name: 'New Page',
    granted: ['PORTAL.update', 'CMS.create'],
    component: PageNew,
  },
  {
    path: '/delete_page/:id',
    name: 'Delete Page',
    granted: ['PORTAL.update', 'CMS.delete'],
    component: PageDelete,
  },
  {
    path: '/edit_page_content/:id',
    name: 'Edit Page Content',
    granted: ['PORTAL.update', 'CMS.update'],
    component: PageContentEdit,
  },
  {
    path: '/edit_generic_template/:id',
    name: 'Edit Generic Template',
    granted: ['PORTAL.update', 'CMS.update'],
    component: GenericTemplateEdit,
  },
  {
    path: '/edit_about_template/:id',
    name: 'AboutTemplateEdit',
    granted: ['PORTAL.update', 'CMS.update'],
    component: AboutTemplateEdit,
  },
  {
    path: '/edit_home_template/:id',
    name: 'HomeTemplateEdit',
    granted: ['PORTAL.update', 'CMS.update'],
    component: HomeTemplateEdit,
  },
  {
    path: '/new_domain/:portal_id',
    name: 'DomainNew',
    granted: 'PORTAL.update',
    component: DomainNew,
  },
  {
    path: '/delete_domain/:id',
    name: 'DomainDelete',
    granted: 'PORTAL.update',
    component: DomainDelete,
  },
  {
    path: '/edit_domain/:id',
    name: 'DomainEdit',
    granted: 'PORTAL.update',
    component: DomainEdit,
  },
  {
    path: '/delete_client_image/:id',
    name: 'ClientImageDelete',
    granted: 'CLIENT.update',
    component: ClientImageDelete,
  },
  /// PRODUCTS
  {
    path: '/products/configurable_variant/:configurable_variant_id/new_option',
    name: 'OptionNew',
    granted: 'PRODUCT.update',
    component: OptionNew,
  },
  {
    path: '/products/configurable_variant/:configurable_variant_id/configurable_option/:id',
    granted: 'PRODUCT.read',
    name: 'OptionShowEdit',
    component: OptionShowEdit,
  },
  {
    path: '/products/configurable_variant/:configurable_variant_id/delete_option/:id',
    name: 'OptionDelete',
    granted: 'PRODUCT.delete',
    component: OptionDelete,
  },
  {
    path: '/products/configurable_variant/:id',
    name: 'VariantShowEdit',
    granted: 'PRODUCT.read',
    component: VariantShowEdit,
  },
  {
    path: '/products/:id/configure',
    name: 'Product Config Wizard',
    granted: 'PRODUCT.update',
    component: ProductConfigWizardForm,
  },
  {
    path: '/products/configurable_variants',
    name: 'ConfigurableVariantList',
    granted: 'PRODUCTS.read',
    component: ConfigurableVariantList,
  },

  {
    path: '/products/new_variant',
    name: 'VariantNew',
    granted: 'PRODUCT.update',
    component: VariantNew,
  },
  {
    path: '/products/delete_variant/:id',
    name: 'VariantDelete',
    granted: 'PRODUCT.delete',
    component: VariantDelete,
  },
  // PRODUCTS NEW
  {
    path: '/products/shq_boxes',
    name: 'ShipperHQ Boxes',
    granted: 'PRODUCT.read',
    component: ShipperHqBoxesGrid,
  },{
    path: '/products/shq_origins',
    name: 'ShipperHqOrigins',
    granted: 'PRODUCT.read',
    component: ShipperHqOriginsGrid,
  },{
    path: '/products/shq_packing_rules',
    name: 'ShipperHQ Packing Rules',
    granted: 'PRODUCT.read',
    component: ShipperHqPackingRulesGrid,
  },{
    path: '/products/shq_shipping_groups',
    name: 'ShipperHQ Groups',
    granted: 'PRODUCT.read',
    component: ShipperHqGroupsGrid,
  },
  {
    path: '/products/create',
    name: 'Create Product',
    granted: 'PRODUCT_WIZARD.create',
    component: SimpleSkuNewForm,
  },
  {
    path: '/products/:id/edit',
    name: 'Edit Product',
    granted: 'PRODUCT_WIZARD.read',
    component: ProductEdit,
  },
  {
    path: '/products/:id',
    name: 'View Product',
    granted: 'PRODUCT_WIZARD.read',
    component: ProductShow,
  },

  {
    path: '/products',
    name: 'Products',
    granted: 'PRODUCT.read',
    component: ProductsGrid,
  },

  // PRODUCTS OLD
  {
    path: '/products_old',
    name: 'Products',
    granted: ['PRODUCT.read', 'BUNDLE_WIZARD.create', 'PRODUCT_WIZARD.create'],
    component: ProductList,
    exact: true,
  },
  {
    path: '/create_product',
    name: 'New Simple SKU',
    granted: ['PRODUCT.create', 'PRODUCT_WIZARD.create'],
    component: SimpleSkuNewForm,
  },
  {
    path: '/product_warehouses/:id',
    name: 'Product Warehouses',
    granted: ['PRODUCT.read', 'WAREHOUSE.read'],
    component: ProductWarehouseList,
  },
  {
    path: '/product_artwork/:id',
    name: 'Product Artwork',
    granted: 'ARTWORK.read',
    component: ProductArtwork,
  },
  {
    path: '/new_product/:client_id',
    name: 'New Product',
    granted: 'PRODUCT.create',
    component: ProductNew,
  },
  {
    path: '/show_product_new_poc/:id',
    name: 'Product',
    granted: 'PRODUCT.read',
    component: ProductShowPOC,
  },
  {
    path: '/show_product/:id',
    name: 'Product',
    granted: 'PRODUCT.read',
    component: ProductShow,
  },
  {
    path: '/edit_product/:id',
    name: 'Edit Product',
    granted: 'PRODUCT.update',
    component: ProductEdit,
  },
  {
    path: '/remove_location_product/:location_id/:product_id',
    name: 'Remove Location Product',
    granted: 'LOCATION.update',
    component: LocationProductRemove,
  },
  {
    path: '/product_artwork/:id',
    name: 'Products',
    granted: 'ARTWORK.read',
    component: ProductArtwork,
  },

  {
    path: '/new_location/:portal_id/:location_group_id',
    name: 'LocationNew',
    granted: 'LOCATION.create',
    component: LocationNew,
  },
  {
    path: '/show_location/:id',
    name: 'LocationShow',
    granted: 'LOCATION.read',
    component: LocationShow,
  },
  {
    path: '/edit_location/:id',
    name: 'LocationEdit',
    granted: 'LOCATION.update',
    component: LocationEdit,
  },
  {
    path: '/delete_location/:id',
    name: 'LocationDelete',
    granted: 'LOCATION.delete',
    component: LocationDelete,
  },
  {
    path: '/ip_blacklist_management',
    name: 'IPBlacklistManagement',
    granted: 'SUPER_ADMIN',
    component: IPBlacklistManagement,
  },
  {
    path: '/ip_whitelist_management',
    name: 'IPWhitelistManagement',
    granted: 'SUPER_ADMIN',
    component: IPWhitelistManagement,
  },

  // Admins
  {
    path: '/admins/create',
    name: 'Create Admin',
    granted: 'ADMIN.create',
    component: AdminNew,
  },
  {
    path: '/admins/:id/edit',
    name: 'Edit Admin',
    granted: 'ADMIN.update',
    component: AdminEdit,
  },
  {
    path: '/admins/:id/delete',
    name: 'Delete Admin',
    granted: 'ADMIN.delete',
    component: AdminDelete,
  },
  {
    path: '/admins/:id',
    name: 'Admin',
    granted: 'ADMIN.read',
    component: AdminShow,
  },

  {
    path: '/admins',
    name: 'Admins',
    granted: 'ADMIN.read',
    component: AdminsGrid,
  },

  {
    path: '/admins_old',
    name: 'Admins',
    granted: 'ADMIN.read',
    component: AdminList,
    exact: true,
  },
  {
    path: '/show_admin/:id',
    name: 'Admin Show',
    granted: 'ADMIN.read',
    component: AdminShow,
  },
  {
    path: '/delete_admin/:id',
    name: 'Delete Admin',
    granted: 'ADMIN.delete',
    component: AdminDelete,
  },
  {
    path: '/edit_admin/:id',
    name: 'Edit Admin',
    granted: 'ADMIN.update',
    component: AdminEdit,
    breadcrumb: 'Admin Edit',
  },
  {
    path: '/new_admin',
    name: 'New Admin',
    granted: 'ADMIN.create',
    component: AdminNew,
  },
  {
    path: '/new_admin_email_domain',
    name: 'AuthorizedAdminEmailDomainCreate',
    granted: 'SUPER_ADMIN',
    component: AuthorizedAdminEmailDomainCreate,
  },
  {
    path: '/authorized_admin_email_domains',
    name: 'AuthorizedAdminEmailDomainsShow',
    granted: 'SUPER_ADMIN',
    component: AuthorizedAdminEmailDomainsShow,
  },
  {
    path: '/delete_admin_email_domain/:id/:name',
    name: 'AuthorizedAdminEmailDomainDelete',
    granted: 'SUPER_ADMIN',
    component: AuthorizedAdminEmailDomainDelete,
  },

  /** ROLES */
  {
    path: '/roles',
    name: 'Roles',
    component: RoleList,
    granted: 'ADMIN.update',
  },
  {
    path: '/new_role',
    name: 'New Role',
    component: RoleNew,
    granted: 'ADMIN.update',
  },
  {
    path: '/show_role/:id',
    name: 'Rolew',
    component: RoleShow,
    granted: 'ADMIN.update',
  },
  {
    path: '/edit_role/:id',
    name: 'Edit Role',
    component: RoleEdit,
    granted: 'ADMIN.update',
  },
  {
    path: '/delete_role/:id',
    name: 'Delete Role',
    component: RoleDelete,
    granted: 'ADMIN.update',
  },

  {
    path: '/delete_category/:id',
    name: 'CategoryDelete',
    granted: 'PORTAL.update',
    component: CategoryDelete,
  },

  {
    path: '/delete_category_location/:id',
    name: 'LocationCategoryList',
    granted: 'PORTAL.update',
    component: LocationCategoryList,
  },
  {
    path: '/show_product_details/:parent/:id/:product_id/:client_id',
    name: 'ProductDetailsShow',
    granted: 'PRODUCT.read',
    component: ProductDetailsShow,
  },
  {
    path: '/edit_aggregated_product_details/:parent/:id/:product_id',
    name: 'ProductDetailsEdit',
    granted: 'PRODUCT.update',
    component: ProductDetailsEdit,
  },

  {
    path: '/new_bundle',
    name: 'BundleCreate',
    granted: ['PRODUCT.create', 'BUNDLE_WIZARD.create'],
    component: BundleCreate,
  },
  {
    path: '/edit_bundle/:id',
    name: 'Edit Bundle',
    granted: 'PRODUCT.update',
    component: BundleEdit,
  },

  {
    path: '/show_location_product_details/:parent/:id/:product_id/:client_id/:portal_id',
    name: 'ProductDetailsLocationShow',
    granted: 'PRODUCT.read',
    component: ProductDetailsLocationShow,
  },
  {
    path: '/edit_aggregated_location_product_details/:parent/:id/:product_id',
    name: 'ProductDetailsLocationEdit',
    granted: 'PRODUCT.update',
    component: ProductDetailsLocationEdit,
  },

  {
    path: '/show_generic_product_details/:parent/:product_id',
    name: 'ProductDetailsNew',
    granted: 'PRODUCT.read',
    component: ProductDetailsNew,
  },
  {
    path: '/edit_aggregated_generic_product_details/:parent/:product_id',
    name: 'ProductDetailsGenericEdit',
    granted: 'PRODUCT.update',
    component: ProductDetailsGenericEdit,
  },

  {
    path: '/show_client_product_details/:parent/:id/:product_id',
    name: 'ProductDetailsClientShow',
    granted: 'PRODUCT.read',
    component: ProductDetailsClientShow,
  },
  {
    path: '/edit_aggregated_client_product_details/:parent/:id/:product_id',
    name: 'ProductDetailsClientEdit',
    granted: 'PRODUCT.update',
    component: ProductDetailsClientEdit,
  },

  {
    path: '/purchase_orders/:id/duplicate',
    name: 'Duplicate Purchase Order',
    granted: 'PURCHASE_ORDER.create',
    component: PurchaseOrderDuplicate,
  },
  {
    path: '/purchase_orders',
    name: 'PurchaseOrderList',
    granted: 'PURCHASE_ORDER.read',
    component: PurchaseOrderList,
  },
  {
    path: '/purchase_orders/items',
    name: 'PurchaseOrderItemsList',
    granted: 'PURCHASE_ORDER.read',
    component: PurchaseOrderItemsList,
  },

  { path: '/not_found_page', name: 'Page404', component: Page404 },
  {
    path: '/internal_server_error_page',
    name: 'NotFoundPage',
    component: Page500,
  },
  // NEW CUSTOMER
  {
    path: '/customers/:id/edit',
    name: 'Edit Customer',
    granted: 'USER.update',
    component: UserEdit,
  },
  {
    path: '/customers/:id/delete',
    name: 'Delete Customer',
    granted: 'USER.delete',
    component: UserDelete,
  },
  {
    path: '/customers/create',
    name: 'Create Customer',
    granted: 'USER.create',
    component: UserNew,
  },

  {
    path: '/customers/:id',
    name: 'Customer',
    granted: ['USER.read', 'USER.read'],
    component: UserShow,
  },
  // CUSTOMERS OLD
  {
    path: '/customers',
    name: 'Customers',
    granted: 'USER.read',
    component: CustomersGrid,
  },
  {
    path: '/customers_old',
    name: 'Users',
    granted: ['USER.read', 'GIFT_CARD.read', 'PORTAL_CREDIT.read', 'COOP_CREDIT.read'],
    component: UserList,
  },
  {
    path: '/show_customer/:id',
    name: 'Customer Show',
    granted: ['USER.read', 'GIFT_CARD.read', 'PORTAL_CREDIT.read', 'COOP_CREDIT.read'],
    component: UserShow,
  },
  {
    path: '/delete_customer/:id',
    name: 'Customer Delete',
    granted: 'USER.delete',
    component: UserDelete,
  },
  {
    path: '/edit_customer/:id',
    name: 'Customer Edit',
    granted: 'USER.update',
    component: UserEdit,
  },
  {
    path: '/new_customer',
    name: 'Customer New',
    granted: 'USER.create',
    component: UserNew,
  },
  {
    path: '/initiate_password_reset_for_all_users',
    name: 'InitiatePasswordResetForAllUsers',
    granted: 'USER.update',
    component: InitiatePasswordResetForAllUsers,
  },

  // {  path: '/customers_poc_ss_1', name: "Users POC", granted:['USER.read', 'GIFT_CARD.read', 'PORTAL_CREDIT.read', 'COOP_CREDIT.read'], component: UserJQXPOC},
  // {  path: '/customers_poc_ss_2', name: "Users POC", granted:['USER.read', 'GIFT_CARD.read', 'PORTAL_CREDIT.read', 'COOP_CREDIT.read'], component: UserJQXPOC2},

  {
    path: '/portal/:id/create_customer_group',
    name: 'NewCustomerGroup',
    granted: ['PORTAL.update', 'CUST_GROUPS.create'],
    component: NewCustomerGroup,
  },
  {
    path: '/portal/:id/create_customer_group_rule',
    name: 'NewCustomerGroupRule',
    granted: ['PORTAL.update', 'CUST_GROUPS.update'],
    component: NewCustomerGroupRule,
  },
  {
    path: '/edit_customer_group_rule/:id',
    name: 'CustomerGroupRuleEdit',
    granted: ['PORTAL.update', 'CUST_GROUPS.update'],
    component: CustomerGroupRuleEdit,
  },
  {
    path: '/customer_group/:id',
    name: 'CustomerGroupShow',
    granted: ['PORTAL.read', 'CUST_GROUPS.read'],
    component: CustomerGroupShow,
  },
  {
    path: '/customer_group_rule/:id',
    name: 'CustomerGroupRuleShow',
    granted: ['PORTAL.read', 'CUST_GROUPS.read'],
    component: CustomerGroupRuleShow,
  },
  {
    path: '/remove_rule_hidden_product/:rule_id/:product_id',
    name: 'Remove Rule Hidden Product',
    component: RuleHiddenProductRemove,
  },
  {
    path: '/remove_rule_hidden_category/:rule_id/:category_id',
    name: 'Remove Rule Hidden Category',
    component: RuleHiddenCategoryRemove,
  },

  {
    path: '/warehouses',
    name: 'Warehouses',
    granted: 'WAREHOUSE.read',
    component: WarehouseList,
  },
  {
    path: '/create_warehouse',
    name: 'Create Warehouse',
    granted: 'WAREHOUSE.create',
    component: WarehouseNew,
  },
  {
    path: '/edit_warehouse/:id',
    name: 'Edit Warehouse',
    granted: 'WAREHOUSE.update',
    component: WarehouseEdit,
  },
  {
    path: '/show_warehouse/:id',
    name: 'Show Warehouse',
    granted: 'WAREHOUSE.read',
    component: WarehouseShow,
  },
  {
    path: '/delete_warehouse/:id',
    name: 'Delete Warehouse',
    granted: 'WAREHOUSE.delete',
    component: WarehouseDelete,
  },
  {
    path: '/show_goods_receipt/:id',
    name: 'Goods Receipt Notes Log',
    granted: 'PURCHASE_ORDER.read',
    component: GoodsReceiptLog,
  },
  {
    path: '/inventory_log/:id',
    name: 'InventoryLog',
    granted: 'WAREHOUSE.read',
    component: InventoryLog,
  },
  {
    path: '/delete_content_block/:id',
    name: 'Delete Content Block',
    granted: 'PORTAL.update',
    component: PageContentBlockDelete,
  },
  {
    path: '/card_transactions_list',
    name: 'Card Transactions',
    component: CardTransactionsList,
  },
  // ORDERS NEW
  {
    path: '/orders/split/:id',
    name: 'Order Split',
    granted: 'ORDER.read',
    component: OrderSplitPage,
  },
  {
    path: '/orders/log',
    name: 'Orders Log',
    granted: 'ORDER.read',
    component: OrderLogsList,
  },
  {
    path: '/orders/review',
    name: 'Orders Review',
    granted: 'ORDER.update',
    component: OrdersReviewGrid,
  },
  {
    path: '/orders/errors',
    name: 'Order Errors',
    granted: 'SUPER_ADMIN.read',
    component: OrderErrorsGrid,
  },
  {
    path: '/orders',
    name: 'Orders',
    granted: 'ORDER.read',
    component: OrdersGrid,
  },
  {
    path: '/order_approval',
    name: 'Order Approval',
    granted: 'ORDER.update',
    component: OrderApprovalList,
  },
  { path: '/delete_image/:id', name: 'Image Delete', component: ImageDelete },
  {
    path: '/show_product_sku/:id',
    name: 'Product SKu Show',
    component: ProductSkuShow,
  },
  {
    path: '/new_base_sku_override/:id',
    name: 'SKU Override Create',
    granted: 'PRODUCT.update',
    component: NewBaseOverride,
  },
  {
    path: '/edit_base_sku_override/:id',
    name: 'SKU Override Update',
    granted: 'PRODUCT.update',
    component: EditBaseOverride,
  },
  {
    path: '/create_artwork',
    name: 'ArtworkCreate',
    granted: 'PRODUCT.create',
    component: ArtworkCreate,
  },
  {
    path: '/artworks',
    name: 'Artworks',
    granted: 'PRODUCT.read',
    component: ArtworkList,
    exact: true,
  },
  {
    path: '/show_artwork/:id',
    name: 'Artwork Show',
    granted: 'PRODUCT.read',
    component: ArtworkShow,
  },
  {
    path: '/artworks/artwork_fields',
    name: 'Artwork Fields',
    granted: 'ARTWORK.update',
    component: ArtworkFieldList,
  },
  {
    path: '/edit_artwork/:id',
    name: 'Artwork Edit',
    granted: 'PRODUCT.read',
    component: ArtworkEdit,
  },
  {
    path: '/delete_artwork/:id',
    name: 'Artwork Delete',
    granted: 'PRODUCT.delete',
    component: ArtworkDelete,
  },
  {
    path: '/positioning_artwork/:id',
    name: 'Artwork Positioning',
    granted: 'PRODUCT.read',
    component: ArtworkPositioning,
  },

  {
    path: '/create_artwork_family',
    name: 'ArtworkFamilyCreate',
    granted: 'PRODUCT.create',
    component: ArtworkFamilyCreate,
  },
  // { path: '/artwork_families', name: 'Artwork Families', component: ArtworkFamilyList },
  // { path: '/show_artwork_family/:id', name: 'Artwork Family Show', component: ArtworkFamilyShow },
  // { path: '/delete_artwork_family/:id', name: 'Artwork Family Delete', component: ArtworkFamilyDelete },

  // {
  //   path: '/fulfilment',
  //   name: 'Fulfilment View',
  //   granted: 'ORDER.read',
  //   component: FulfilmentView,
  // },

  {
    path: '/fulfillment',
    name: 'Fulfilment View',
    granted: 'ORDER.read',
    component: FulfilmentViewNew,
  },
  {
    path: '/dropship',
    name: 'Dropshipping',
    granted: 'ORDER.read',
    component: FulfilmentView,
  },

  {
    path: '/show_promotion/:id',
    name: 'Promotion Show',
    granted: 'COUPON.read',
    component: PromotionShow,
  },
  {
    path: '/new_promotion',
    name: 'Create Promotion',
    granted: 'COUPON.create',
    component: PromotionNew,
  },
  {
    path: '/delete_promotion/:id',
    name: 'PromotionDelete',
    granted: 'COUPON.delete',
    component: PromotionDelete,
  },
  {
    path: '/promotions',
    name: 'Promotion',
    granted: 'COUPON.read',
    component: PromotionList,
    exact: true,
  },

  {
    path: '/promotion_validation_new/:promotion_id',
    name: 'Validation New',
    granted: 'COUPON.update',
    component: PromotionValidationNew,
  },
  {
    path: '/show_promotion_validation/:id',
    name: 'Validation Show',
    granted: 'COUPON.read',
    component: PromotionValidationShow,
  },
  {
    path: '/delete_promotion_validation/:id',
    name: 'PromotionValidationDelete',
    granted: 'COUPON.update',
    component: PromotionValidationDelete,
  },

  {
    path: '/promotion_rule_new/:promotion_id',
    name: 'Rule New',
    granted: 'COUPON.update',
    component: PromotionRuleNew,
  },
  {
    path: '/show_promotion_rule/:id',
    name: 'Rule Show',
    granted: 'COUPON.read',
    component: PromotionRuleShow,
  },
  {
    path: '/delete_promotion_rule/:id',
    name: 'PromotionRuleDelete',
    granted: 'COUPON.update',
    component: PromotionRuleDelete,
  },

  //Purchase orders
  {
    path: '/purchase_order_new',
    name: 'Purchase Order New',
    granted: 'PURCHASE_ORDER.create',
    component: PurchaseOrderNew,
  },
  {
    path: '/purchase_order_edit/:id',
    name: 'Purchase Order Edit',
    granted: 'PURCHASE_ORDER.update',
    component: PurchaseOrderEdit,
  },
  {
    path: '/purchase_orders',
    name: 'Purchase Order',
    granted: 'PURCHASE_ORDER.read',
    component: PurchaseOrderList,
  },
  {
    path: '/show_purchase_order/:id',
    name: 'Purchase Order Show',
    granted: 'PURCHASE_ORDER.read',
    component: PurchaseOrderShow,
  },

  {
    path: '/purchase_orders/:id/generate_purchase_order',
    name: 'Purchase Order PDF',
    granted: 'PURCHASE_ORDER.read',
    component: PurchaseOrderShow,
  },

  {
    path: '/promotion_code_new/:promotion_id',
    name: 'Promotion New Code ',
    granted: 'COUPON.update',
    component: PromotionCodeNew,
  },
  {
    path: '/delete_promotion_code/:id',
    name: 'Promotion Delete Code ',
    granted: 'COUPON.update',
    component: PromotionCodeDelete,
  },
  {
    path: '/edit_promotion_code/:id',
    name: 'Promotion Edit Code',
    granted: 'COUPON.update',
    component: PromotionCodeEdit,
  },
  //GOODS RECEIPTS
  {
    path: '/goods_receipt_new/:purchase_order_id',
    name: 'Goods Receipt New',
    granted: 'PURCHASE_ORDER.update',
    component: GoodsReceiptNew,
  },
  {
    path: '/settings',
    name: 'SettingList',
    granted: 'SUPER_ADMIN',
    component: SettingList,
  },
  {
    path: '/edit_setting/:id',
    name: 'SettingEdit',
    granted: 'SUPER_ADMIN',
    component: SettingEdit,
  },

  //Template
  {
    path: '/template_new',
    name: 'Template Form New',
    granted: 'PRODUCT.update',
    component: TemplateFormNew,
  },
  {
    path: '/templates',
    name: 'Templates',
    granted: 'PRODUCT.read',
    component: TemplateFormList,
  },
  {
    path: '/edit_template/:id',
    name: 'Template Form Edit',
    granted: 'PRODUCT.update',
    component: TemplateEdit,
  },
  {
    path: '/show_template/:id',
    name: 'Template Form Show',
    granted: 'PRODUCT.read',
    component: TemplateFormShow,
  },
  {
    path: '/delete_template/:id',
    name: 'Template Form Delete',
    granted: 'PRODUCT.update',
    component: TemplateFormDelete,
  },

  // Vendors
  {
    path: '/vendors',
    name: 'Vendors',
    granted: 'VENDORS.read',
    component: VendorList,
  },
  {
    path: '/vendor_new',
    name: 'Vendor New',
    granted: 'VENDORS.create',
    component: VendorNew,
  },
  {
    path: '/vendor_edit/:id',
    name: 'Vendor Update',
    granted: 'VENDORS.update',
    component: VendorEdit,
  },
  {
    path: '/vendor_show/:id',
    name: 'Vendor Show',
    granted: 'VENDORS.read',
    component: VendorShow,
  },
  {
    path: '/vendor_delete/:id',
    name: 'Vendor Delete',
    granted: 'VENDORS.delete',
    component: VendorDelete,
  },
  // Dropship Batches
  {
    path: '/dropship_batch/:id',
    name: 'Dropship Batch Show',
    granted: 'DROPSHIP_BATCH.read',
    component: DropshipBatchShow,
  },
  {
    // temp for demoing
    path: '/portal_show_edit_poc/:id',
    name: 'PortalShowEditPOC',
    granted: 'ADMIN.update',
    component: PortalShowEditPOC,
  },
  // NEW PORTAL
  {
    path: '/portals/:id/import_users',
    name: 'Import Customers',
    granted: 'PORTAL.update',
    component: ImportCustomersGrid,
  },
  {
    path: '/portals_old',
    name: 'Portals_old',
    granted: [
      'PORTAL.read',
      'LOCATION.read',
      'CMS.read',
      'COOP_FUNDING.read',
      'CUST_GROUPS.read',
      'COOP_CREDIT.read',
      'CREDIT_REDEMPTION.read',
    ],
    component: PortalList,
  },
  {
    path: '/portal/pages',
    name: 'Pages',
    granted: 'PORTAL.create',
    component: PageList,
  },
  {
    path: '/good_receipts/item',
    name: 'GoodReceiptItemList',
    granted: 'PURCHASE_ORDER.update',
    component: GoodReceiptItemList,
  },
  {
    path: '/po_items',
    name: 'Purchase Order Items',
    granted: 'PURCHASE_ORDER.read',
    component: PurchaseOrderItemsGrid,
  },
  {
    path: '/portal_product_grid_temp',
    name: 'PortalProductGrid',
    granted: 'PORTAL.read',
    component: PortalProductGrid,
  },{
    path: '/orders',
    name: 'Orders',
    granted: 'ORDER.read',
    component: OrdersGrid,
  },
{
    path: '/portals',
    name: 'Portals',
    granted: 'PORTAL.read',
    component: PortalsGrid,
  }]

// PLEASE DO NOT END THE Routes ARRAY WITH A COMMA
