import { Suspense } from 'react'
import { Switch, withRouter, Route } from 'react-router-dom'
import BlankLayout from '../@core/layouts/BlankLayout'
import LayoutWrapper from '../@core/layouts/components/layout-wrapper/index.js'
import LoadingSpinner from '../components/general/LoadingSpinner.js'
import VerticalLayout from '../containers/DefaultLayout/VerticalLayout'
import { useMFAdminAppInitializer } from '../contexts/MFAdminAppInitializer/MFAdminAppInitializer.context.js'
import history from '../history'
import { Routes } from '../routes.js'

import { useLayout } from '../utility/hooks/useLayout'
import Error from '../views/pages/misc/Error'
import FinalRoute from './FinalRoute.js'

export default function ResolveRoutes(props) {
  // TODO: swap with initializer context
  const [layout, setLayout] = useLayout('VerticalLayout')
  const {
    state: {
      layout: { routerTransition: transition }
    },
    actions: {
      layout: { SET_ROUTER_TRANSITION }
    }
  } = useMFAdminAppInitializer()

  const DefaultLayout = layout === 'horizontal' ? 'HorizontalLayout' : 'VerticalLayout'

  const Layouts = { BlankLayout, VerticalLayout /*HorizontalLayout*/ }

  const currentActiveItem = null

  const LayoutRoutesAndPaths = layout => {
    const LayoutRoutes = []
    const LayoutPaths = []

    if (Routes) {
      // Filter routes for the current layout
      Routes.filter(route => {
        // ** Checks if Route layout matches or is undefined and Default layout is current
        if (route.layout === layout || (route.layout === undefined && DefaultLayout === layout)) {
          LayoutRoutes.push(route)
          LayoutPaths.push(route.path)
        }
      })
    }

    return { LayoutRoutes, LayoutPaths }
  }

  return Object.keys(Layouts).map((layout, index) => {
    // ** Convert Layout parameter to Layout Component
    // ? Note: make sure to keep layout and component name equal

    const LayoutTag = Layouts[layout]

    // ** Get Routes and Paths of the Layout
    const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout)

    // ** We have freedom to display different layout for different route
    // ** We have made LayoutTag dynamic based on layout, we can also replace it with the only layout component,
    // ** that we want to implement like VerticalLayout or HorizontalLayout
    // ** We segregated all the routes based on the layouts and Resolved all those routes inside layouts

    // ** RouterProps to pass them to Layouts
    const routerProps = { history }

    return (
      <Route path={LayoutPaths} key={index}>
        <LayoutTag
          routerProps={routerProps}
          layout={layout}
          setLayout={setLayout}
          setTransition={SET_ROUTER_TRANSITION}
          currentActiveItem={currentActiveItem}
        >
          <Suspense fallback={<LoadingSpinner />}>
            <Switch>
              {LayoutRoutes.map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  render={props => {
                    Object.assign(routerProps, {
                      ...props,
                      meta: route.meta
                    })
                    return (
                      <LayoutWrapper
                        layout={DefaultLayout}
                        transition={transition}
                        setTransition={SET_ROUTER_TRANSITION}
                        {...(route.appLayout ? { appLayout: route.appLayout } : {})}
                        {...(route.meta ? { routeMeta: route.meta } : {})}
                        {...(route.className ? { wrapperClass: route.className } : {})}
                      >
                        <FinalRoute route={route} {...props} />
                      </LayoutWrapper>
                    )
                  }}
                />
              ))}
              <Route path="*" component={Error} />
            </Switch>
          </Suspense>
        </LayoutTag>
      </Route>
    )
  })
}
