import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { saveUrlToLocalStorage } from '../../utility/Utils'

const SsoFeaturebaseRedirect = ({ featurebaseAuthenticationToken, isAdminLoading }) => {
  const history = useHistory()
  const location = useLocation()
  const returnTo = new URLSearchParams(location.search).get('return_to') || 'https://feedback.marketfuel.com/changelog'

  useEffect(() => {
    // wait for admin to be available
    if (isAdminLoading) {
      return
    }

    // redirect to featurebase if auth token exists, otherwise redirect to login
    if (featurebaseAuthenticationToken) {
      const redirectUrl = `https://marketfuel.featurebase.app/api/v1/auth/access/jwt?jwt=${featurebaseAuthenticationToken}&return_to=${returnTo}`
      window.location.href = redirectUrl
    } else {
      // save this endpoint's path to local storage so we can redirect back here after login
      saveUrlToLocalStorage(`/sso/external/featurebase?return_to=${returnTo}`)
      history.push('/login')
    }
  }, [featurebaseAuthenticationToken])

  return null
}

const mapStateToProps = state => ({
  featurebaseAuthenticationToken: state.admins.current_admin.featurebase_authentication_token,
  isAdminLoading: state.loading.admin
})

export default connect(mapStateToProps)(SsoFeaturebaseRedirect)
